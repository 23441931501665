<template>
    <div>
        <v-col class="my-0 py-0 px-0 px-md-8 pb-15">
            <v-col class="text-start mt-5 mb-3">
                <v-alert text dense color="#8061c2" border="left" class="pl-5">
                    <h1 class="title mt-1">Logs del sistema</h1>
                    <p class="desc mt-0 mb-1">Registro de cambios en la plataforma.</p>
                </v-alert>
            </v-col>

            <!-- Indicador de sincronización -->
            <!--<v-row class="mx-3 mb-2" v-if="syncing">
                <v-col cols="12" class="pa-0">
                    <v-alert dense text color="info" class="mb-0 d-flex align-center">
                        <v-progress-circular indeterminate size="20" width="2" color="info" class="mr-3"></v-progress-circular>
                        Sincronizando datos...
                    </v-alert>
                </v-col>
            </v-row>

            <v-row class="mx-3 mb-2" v-else>
                <v-col cols="12" class="pa-0">
                    <v-alert dense text color="gray" class="mb-0 d-flex align-center">
                        <v-icon class="mr-3">mdi-check</v-icon>
                        <span style="color: gray;">Ultima sincronización hace 5 minutos</span>
                    </v-alert>
                </v-col>
            </v-row>-->

            <!-- Barra de búsqueda y filtros -->
            <v-row class="mx-3">
                <v-col cols="12" md="4" class="px-0">
                    <v-text-field 
                        v-model="search" 
                        color="#8061c2" 
                        label="Buscar en logs" 
                        append-icon="mdi-magnify" 
                        single-line 
                        hide-details 
                        outlined 
                        class="mb-2 mx-0 px-0" 
                        @input="onSearch"
                    ></v-text-field>
                </v-col>

                <v-col cols="12" md="3" class="px-md-2">
                    <v-select
                        v-model="logType"
                        :items="logTypeOptions"
                        label="Tipo"
                        outlined
                        @change="onFilterChange"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="3" class="px-md-2">
                    <v-select
                        v-model="fieldType"
                        :items="fieldTypeOptions"
                        label="Campo"
                        outlined
                        @change="onFilterChange"
                    ></v-select>
                </v-col>

                <v-col cols="12" md="2" class="px-md-2">
                    <v-select
                        v-model="sortOrder"
                        :items="sortDirectionOptions"
                        label="Orden"
                        outlined
                        @change="fetchLogs"
                    ></v-select>
                </v-col>
            </v-row>

            <!-- Tabla de logs -->
            <v-data-table
                :headers="headers"
                :items="logs"
                :loading="initialLoad"
                fixed-header
                height="calc(100vh - 250px)"
                class="elevation-1 mx-3 px-2 py-4 logs-table"
                :items-per-page="perPage"
                :server-items-length="totalLogs"
                :page.sync="page"
                :footer-props="{
                    itemsPerPageOptions: [50, 100, 200, -1],
                    showFirstLastPage: true
                }"
                @update:page="onPageChange"
                @update:items-per-page="changePerPage"
            >
                <!-- Columna Cambiado Por -->
                <template v-slot:item.changed_by="{ item }">
                    <div class="d-flex align-center">
                        <v-avatar size="30" class="mr-2">
                            <v-img v-if="item.changed_by?.logo_path" :src="item.changed_by.logo_path"></v-img>
                            <v-avatar v-else color="black" size="30">
                                <span class="white--text">{{ getInitials(item.changed_by) }}</span>
                            </v-avatar>
                        </v-avatar>
                        {{ item.changed_by ? `${item.changed_by.first_name} ${item.changed_by.last_name}` : 'N/A' }}
                    </div>
                </template>

                <!-- Columna Tipo de Log -->
                <template v-slot:item.log_type="{ item }">
                    <v-chip :color="getLogTypeColor(item.log_type)" dark small>
                        {{ item.log_type }}
                    </v-chip>
                </template>

                <!-- Columna Usuario -->
                <template v-slot:item.user="{ item }">
                    {{ item.user ? item.user.email : 'N/A' }}
                </template>

                <!-- Columna Sitio -->
                <template v-slot:item.site="{ item }">
                    <div class="d-flex align-center">
                        {{ item.site ? item.site.name : 'N/A' }}
                        <v-btn
                            v-if="item.site"
                            icon
                            small
                            class="ml-2"
                            @click="goToSite(item.site.slug)"
                        >
                            <v-icon small>mdi-open-in-new</v-icon>
                        </v-btn>
                    </div>
                </template>

                <!-- Columna Valor Anterior -->
                <template v-slot:item.old_value="{ item }">
                    <template v-if="isMediaPath(item.old_value)">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <!-- Si el archivo NO está marcado como eliminado -->
                                <div v-if="!item.oldValueDeleted && item.old_value" 
                                    class="miniatura clickable-media"
                                    @click="showMedia(item.old_value, getMediaType(item.old_value))"
                                    v-bind="attrs" 
                                    v-on="on">
                                    <template v-if="getMediaType(item.old_value) === 'video'">
                                        <video 
                                            :src="item.old_value" 
                                            :autoplay="true" 
                                            :loop="true" 
                                            muted 
                                            :preload="'auto'"
                                            :playsinline="true">
                                        </video>
                                    </template>
                                    <template v-else>
                                        <img :src="item.old_value" />
                                    </template>
                                </div>
                                <!-- Si está marcado como eliminado o no hay valor -->
                                <div v-else class="miniatura clickable-media" v-bind="attrs" v-on="on">
                                    <v-icon large color="grey">mdi-file-remove-outline</v-icon>
                                    <span class="ml-2 grey--text">Archivo eliminado</span>
                                </div>
                            </template>
                            <span>{{ item.old_value }}</span>
                        </v-tooltip>
                    </template>
                    <template v-else>
                        <v-tooltip bottom v-if="item.old_value && item.old_value.length > 30">
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    {{ truncateText(item.old_value) }}
                                    <v-icon small class="ml-1" color="primary">mdi-dots-horizontal</v-icon>
                                    <div v-if="isColorCode(item.old_value)" 
                                        class="color-circle ml-2" 
                                        :style="{ backgroundColor: item.old_value }">
                                    </div>
                                </div>
                            </template>
                            <span>{{ item.old_value }}</span>
                        </v-tooltip>
                        <div v-else class="d-flex align-center">
                            <span>{{ item.old_value || 'N/A' }}</span>
                            <div v-if="isColorCode(item.old_value)" 
                                class="color-circle ml-2" 
                                :style="{ backgroundColor: item.old_value }">
                            </div>
                        </div>
                    </template>
                </template>

                <!-- Columna Valor Nuevo -->
                <template v-slot:item.new_value="{ item }">
                    <template v-if="isMediaPath(item.new_value)">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <!-- Si el archivo NO está marcado como eliminado -->
                                <div v-if="!item.newValueDeleted && item.new_value" 
                                    class="miniatura clickable-media"
                                    @click="showMedia(item.new_value, getMediaType(item.new_value))"
                                    v-bind="attrs" 
                                    v-on="on">
                                    <template v-if="getMediaType(item.new_value) === 'video'">
                                        <video 
                                            :src="item.new_value" 
                                            :autoplay="true" 
                                            :loop="true" 
                                            muted 
                                            :preload="'auto'"
                                            :playsinline="true">
                                        </video>
                                    </template>
                                    <template v-else>
                                        <img :src="item.new_value" />
                                    </template>
                                </div>
                                <!-- Si está marcado como eliminado o no hay valor -->
                                <div v-else class="miniatura clickable-media" v-bind="attrs" v-on="on">
                                    <v-icon large color="grey">mdi-file-remove-outline</v-icon>
                                    <span class="ml-2 grey--text">Archivo eliminado</span>
                                </div>
                            </template>
                            <span>{{ item.new_value }}</span>
                        </v-tooltip>
                    </template>
                    <template v-else>
                        <v-tooltip bottom v-if="item.new_value && item.new_value.length > 30">
                            <template v-slot:activator="{ on, attrs }">
                                <div class="d-flex align-center" v-bind="attrs" v-on="on">
                                    {{ truncateText(item.new_value) }}
                                    <v-icon small class="ml-1" color="primary">mdi-dots-horizontal</v-icon>
                                    <div v-if="isColorCode(item.new_value)" 
                                        class="color-circle ml-2" 
                                        :style="{ backgroundColor: item.new_value }">
                                    </div>
                                </div>
                            </template>
                            <span>{{ item.new_value }}</span>
                        </v-tooltip>
                        <div v-else class="d-flex align-center">
                            <span>{{ item.new_value || 'N/A' }}</span>
                            <div v-if="isColorCode(item.new_value)" 
                                class="color-circle ml-2" 
                                :style="{ backgroundColor: item.new_value }">
                            </div>
                        </div>
                    </template>
                </template>

                <!-- Columna Fecha -->
                <template v-slot:item.created_at="{ item }">
                    {{ formatDateTime(item.created_at) }}
                </template>
            </v-data-table>
        </v-col>

        <!-- Agregar el diálogo para ver media -->
        <v-dialog v-model="dialog" max-width="95vh" width="unset" class="rounded-lg dialog-media">
            <div class="media-container">
                <video v-if="mediaType === 'video'"
                    :src="selectedLogo"
                    :autoplay="true"
                    :loop="true"
                    muted
                    :preload="'auto'"
                    :playsinline="true"
                    class="transition-ease cursor-pointer media">
                </video>
                <img v-else
                    :src="selectedLogo"
                    class="transition-ease cursor-pointer media"
                    alt="Media content" />
            </div>
        </v-dialog>
    </div>
</template>

<script>
import axios from '@/axios.js';
import moment from 'moment';

export default {
    data() {
        return {
            logs: [],
            search: '',
            loading: false,
            syncing: false,
            initialLoad: true,
            page: 1,
            perPage: 50,
            totalLogs: 0,
            sortField: 'created_at',
            sortOrder: 'desc',
            logType: 'all',
            fieldType: 'all',
            refreshInterval: null,
            logTypeOptions: [
                { text: 'Todos', value: 'all' },
                { text: 'Creados', value: 'create' },
                { text: 'Actualizados', value: 'update' },
                { text: 'Eliminados', value: 'delete' }
            ],
            fieldTypeOptions: [
                { text: 'Todos', value: 'all' },
                { text: 'Monetización', value: 'monetization' },
                { text: 'Impersonar', value: 'impersonate' },
                { text: 'Media', value: 'media' },
                { text: 'Retiros', value: 'withdrawal' },
                { text: 'Accesos', value: 'login' },
                { text: 'Registros', value: 'register' },
                { text: 'Usuario', value: 'user' },
                { text: 'Sitio', value: 'site' }
            ],
            sortDirectionOptions: [
                { text: 'Más reciente', value: 'desc' },
                { text: 'Más antiguo', value: 'asc' }
            ],
            headers: [
                { text: 'Cambiado Por', value: 'changed_by', align: 'start' },
                { text: 'Tipo', value: 'log_type', align: 'center' },
                { text: 'Usuario', value: 'user', align: 'start' },
                { text: 'Sitio', value: 'site', align: 'start' },
                { text: 'Campo', value: 'field', align: 'start' },
                { text: 'Valor Anterior', value: 'old_value', align: 'start' },
                { text: 'Valor Nuevo', value: 'new_value', align: 'start' },
                { text: 'Fecha', value: 'created_at', align: 'start' }
            ],
            dialog: false,
            mediaType: null,
            selectedLogo: null
        };
    },

    created() {
        this.setInitialFilters();
        this.fetchLogs();
        this.startAutoRefresh();
    },

    beforeDestroy() {
        this.stopAutoRefresh();
    },

    methods: {
        setInitialFilters() {
            const params = new URLSearchParams(window.location.search);
            const userEmail = params.get('user_email');
            if (userEmail) {
                this.search = decodeURIComponent(userEmail);
            }
            const type = params.get('type');
            if (type) {
                this.fieldType = type;
            }
        },

        startAutoRefresh() {
            this.refreshInterval = setInterval(() => {
                this.fetchLogs();
            }, 5000);
        },

        stopAutoRefresh() {
            if (this.refreshInterval) {
                clearInterval(this.refreshInterval);
                this.refreshInterval = null;
            }
        },

        async fetchLogs() {
            if (this.initialLoad) {
                this.loading = true;
            } else {
                this.syncing = true;
            }

            try {
                const response = await axios.get('/admin/get-logs', {
                    params: {
                        page: this.page,
                        per_page: this.perPage,
                        search: this.search,
                        sort_field: 'created_at',
                        sort_order: this.sortOrder,
                        log_type: this.logType !== 'all' ? this.logType : null,
                        field_type: this.fieldType !== 'all' ? this.fieldType : null
                    }
                });

                const wrapper = document.querySelector('.v-data-table__wrapper');
                const scrollPosition = wrapper ? wrapper.scrollTop : 0;

                this.logs = response.data.data.data;
                this.totalLogs = response.data.total;

                if (wrapper) {
                    this.$nextTick(() => {
                        wrapper.scrollTop = scrollPosition;
                    });
                }
            } catch (error) {
                console.error('Error fetching logs:', error);
                this.$vs.notify({
                    title: 'Error',
                    text: 'No se pudieron cargar los logs',
                    color: 'danger',
                    position: 'bottom-center'
                });
            } finally {
                this.loading = false;
                this.syncing = false;
                this.initialLoad = false;
            }
        },

        getLogTypeColor(type) {
            const colors = {
                'create': 'success',
                'update': 'warning',
                'delete': 'error'
            };
            return colors[type] || 'grey';
        },

        isColorCode(value) {
            return value && /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(value);
        },

        getInitials(user) {
            if (!user) return 'N/A';
            return `${user.first_name.charAt(0)}${user.last_name.charAt(0)}`;
        },

        truncateText(text) {
            if (!text) return 'N/A';
            return text.length > 20 ? text.substring(0, 20) + ' ' : text;
        },

        formatDateTime(date) {
            return moment(date).format('DD/MM/YYYY hh:mm A');
        },

        goToSite(slug) {
            window.open(`/${slug}`, '_blank');
        },

        onSearch() {
            this.page = 1;
            this.fetchLogs();
        },

        onPageChange() {
            this.fetchLogs();
        },

        changePerPage(newPerPage) {
            this.perPage = newPerPage;
            this.page = 1;
            this.fetchLogs();
        },

        onFilterChange() {
            this.page = 1;
            this.fetchLogs();
        },

        isMediaPath(value) {
            return value && (
                value.includes('/backgrounds/') ||
                value.includes('/logo/') ||
                value.includes('/logos/') ||
                value.includes('/videos/') ||
                value.includes('/galleries/') ||
                value.includes('/images/')
            );
        },

        getMediaType(path) {
            if (!path) return null;
            return path.includes('/videos/') ? 'video' : 'image';
        },

        showMedia(media, type) {
            this.mediaType = type;
            this.selectedLogo = media;
            this.dialog = true;
        }
    }
};
</script>

<style scoped>
.title {
    font-size: 22px !important;
    color: #8061c2;
}

.desc {
    color: #737373;
    font-size: 15px;
}

.logs-table {
    background: white;
}

.color-circle {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 1px solid #ddd;
}

::v-deep .v-data-table__wrapper {
    overflow-y: auto;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    background: white;
    z-index: 1;
}

::v-deep .v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    box-shadow: 0 2px 4px -1px rgba(0,0,0,0.1);
}

.miniatura {
    width: 100px;
    height: 40px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f5f5;
    border-radius: 4px;
}

.clickable-media {
    cursor: pointer;
}

.miniatura img,
.miniatura video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.dialog-media {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.media-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    max-height: 100%;
}

.media {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}
</style>
